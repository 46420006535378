import { ReactElement } from 'react'

export interface IProps {
  className?: string
}

export const LessonIcon = (): ReactElement => (
  <svg
    version='1.1'
    id='Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    viewBox='0 0 256 190'
    enableBackground='new 0 0 256 190'
    xmlSpace='preserve'
    fill='currentColor'
  >
    <path
      d='M48.12,27.903C48.12,13.564,59.592,2,74.023,2c14.339,0,25.903,11.564,25.903,25.903
	C99.834,42.335,88.27,53.806,74.023,53.806C59.684,53.806,48.12,42.242,48.12,27.903z M191,139h-47V97c0-20.461-17.881-37-38-37H43
	C20.912,60,1.99,79.14,2,98v77c-0.026,8.533,6.001,12.989,12,13c6.014,0.011,12-4.445,12-13v-75h8v88h78v-88h8l0.081,50.37
	c-0.053,8.729,5.342,12.446,10.919,12.63h60C207.363,163,207.363,139,191,139z M168.711,120.755c16.993,0,25.774,2.739,29.553,4.418
	H157V72.326h6.627v48.456l4.105-0.027H168.711z M203.22,73.855c0,8.156,0,49.448,0,49.448s-7.626-6.627-34.495-6.627h-1.02
	c0-7.817,0-49.788,0-49.788s1.529-0.061,3.949-0.061h0.007C179.981,66.827,198.883,67.541,203.22,73.855z M243.268,120.755
	l4.105,0.027V72.326H254v52.847h-41.265c3.779-1.679,12.561-4.418,29.553-4.418H243.268z M239.339,66.827h0.007
	c2.42,0,3.949,0.061,3.949,0.061s0,41.971,0,49.788h-1.02c-26.869,0-34.495,6.627-34.495,6.627s0-41.292,0-49.448
	C212.117,67.541,231.019,66.827,239.339,66.827z'
    />
  </svg>
)

export const RegisterIcon = (): ReactElement => (
  <svg fill='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
    <path d='M18 9H4V8h14zm-5 3H4v1h9zm8-8v9h-1V5H2v13h9v1H1V4zm2.07 11.637l-.707-.707-5.863 5.863-2.863-2.863-.707.707 3.57 3.57z' />
    <path fill='none' d='M0 0h24v24H0z' />
  </svg>
)

export const SubjectEvaluationIcon = (): ReactElement => (
  <svg fill='currentColor' version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' viewBox='0 0 470 470' xmlSpace='preserve'>
    <g>
      <path
        d='M192.465,294.786h-12.5v-25.895c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v25.895H148.31v-55.895
   c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v55.895h-16.654v-25.895c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v25.895H85
   v-55.895c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v63.395c0,4.142,3.357,7.5,7.5,7.5h114.965c4.143,0,7.5-3.358,7.5-7.5
   S196.607,294.786,192.465,294.786z'
      />
      <path
        d='M192.465,53.251H77.5c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h114.965c4.143,0,7.5-3.358,7.5-7.5
   S196.607,53.251,192.465,53.251z'
      />
      <path
        d='M192.465,88.879H77.5c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h114.965c4.143,0,7.5-3.358,7.5-7.5
   S196.607,88.879,192.465,88.879z'
      />
      <path
        d='M77.5,139.507h57.482c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5H77.5c-4.143,0-7.5,3.358-7.5,7.5
   S73.357,139.507,77.5,139.507z'
      />
      <path
        d='M192.465,160.135H77.5c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h114.965c4.143,0,7.5-3.358,7.5-7.5
   S196.607,160.135,192.465,160.135z'
      />
      <path
        d='M192.465,195.763H77.5c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h114.965c4.143,0,7.5-3.358,7.5-7.5
   S196.607,195.763,192.465,195.763z'
      />
      <path
        d='M235,58.675c-4.143,0-7.5,3.358-7.5,7.5v252.848c0,4.142,3.357,7.5,7.5,7.5s7.5-3.358,7.5-7.5V66.175
   C242.5,62.032,239.143,58.675,235,58.675z'
      />
      <path
        d='M462.5,73.251H440v-52.5c0-4.142-3.357-7.5-7.5-7.5H263.732c-10.816,0-21.035,4.135-28.732,11.373
   c-7.698-7.238-17.917-11.373-28.732-11.373H37.5c-4.143,0-7.5,3.358-7.5,7.5v52.5H7.5c-4.143,0-7.5,3.358-7.5,7.5v321.535
   c0,4.142,3.357,7.5,7.5,7.5h197.337l8.396,9.793c1.425,1.662,3.505,2.618,5.694,2.618l32.152-0.003c2.189,0,4.27-0.957,5.694-2.62
   l8.389-9.789h41.087v27.463c0,10.752,8.748,19.5,19.502,19.5h16c10.751,0,19.498-8.748,19.498-19.5v-27.463H462.5
   c4.143,0,7.5-3.358,7.5-7.5V80.751C470,76.609,466.643,73.251,462.5,73.251z M346.25,126.249v252.5h-25v-252.5H346.25z
    M321.25,393.749h25v15h-25V393.749z M325,111.249l8.751-21l8.749,21H325z M341.752,441.749h-16c-2.44,0-4.502-2.061-4.502-4.5
   v-13.5h25v13.5C346.25,439.688,344.19,441.749,341.752,441.749z M455,394.786h-93.75V118.749c0-0.25-0.013-0.499-0.038-0.747
   c-0.021-0.207-0.054-0.41-0.091-0.612c-0.007-0.038-0.01-0.077-0.018-0.115c-0.103-0.515-0.258-1.011-0.461-1.483l-19.968-47.927
   c-1.164-2.795-3.895-4.616-6.923-4.616c-3.027,0-5.759,1.82-6.923,4.615l-19.974,47.932c-0.201,0.47-0.356,0.964-0.459,1.478
   c-0.008,0.042-0.011,0.084-0.019,0.126c-0.036,0.198-0.07,0.398-0.09,0.601c-0.025,0.248-0.038,0.497-0.038,0.747v276.037h-42.518
   c-3.839,0-7.414,1.824-9.675,4.907l-6.429,7.502l-25.254,0.002l-6.433-7.503c-2.264-3.084-5.84-4.908-9.675-4.908H15V88.251h15
   v254.035c0,4.142,3.357,7.5,7.5,7.5h168.768c8.344,0,16.128,3.834,21.232,10.343v10.42c-6.34-3.718-13.637-5.763-21.232-5.763H37.5
   c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h168.768c9.057,0,17.463,4.51,22.488,12.063c0.165,0.246,0.339,0.473,0.525,0.692
   c0.049,0.058,0.102,0.112,0.153,0.168c0.139,0.154,0.282,0.302,0.433,0.444c0.067,0.063,0.134,0.124,0.203,0.184
   c0.158,0.138,0.323,0.269,0.492,0.394c0.056,0.041,0.11,0.085,0.167,0.125c0.234,0.163,0.477,0.314,0.73,0.45
   c0.248,0.133,0.505,0.248,0.767,0.353c0.062,0.025,0.126,0.046,0.189,0.069c0.205,0.075,0.412,0.142,0.625,0.199
   c0.077,0.021,0.155,0.042,0.233,0.06c0.209,0.049,0.421,0.088,0.636,0.12c0.075,0.011,0.149,0.025,0.225,0.034
   c0.285,0.033,0.574,0.054,0.868,0.054c0.297,0,0.583-0.021,0.867-0.054c0.079-0.009,0.156-0.024,0.234-0.036
   c0.211-0.031,0.42-0.07,0.626-0.118c0.081-0.019,0.16-0.04,0.24-0.062c0.209-0.057,0.414-0.123,0.615-0.197
   c0.066-0.024,0.132-0.046,0.197-0.072c0.258-0.103,0.51-0.217,0.753-0.346c0.265-0.141,0.506-0.292,0.739-0.454
   c0.06-0.041,0.116-0.087,0.175-0.131c0.167-0.123,0.328-0.251,0.484-0.387c0.07-0.061,0.139-0.124,0.207-0.188
   c0.149-0.14,0.291-0.286,0.427-0.438c0.053-0.058,0.107-0.114,0.158-0.174c0.185-0.218,0.358-0.444,0.517-0.682
   c5.029-7.561,13.435-12.071,22.494-12.071h20.018c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5h-20.018
   c-7.596,0-14.892,2.044-21.232,5.762v-10.419c5.102-6.508,12.887-10.343,21.232-10.343h20.018c4.143,0,7.5-3.358,7.5-7.5
   s-3.357-7.5-7.5-7.5h-20.018c-10.817,0-21.035,4.131-28.733,11.364c-7.698-7.232-17.916-11.364-28.731-11.364H45V28.251h161.268
   c9.056,0,17.461,4.514,22.486,12.075c1.39,2.092,3.734,3.349,6.246,3.349s4.856-1.257,6.247-3.349
   c5.023-7.561,13.43-12.074,22.485-12.074H425v306.535h-41.25c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h48.75
   c4.143,0,7.5-3.358,7.5-7.5V88.251h15V394.786z'
      />
      <path
        d='M432.5,364.786h-48.75c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h48.75c4.143,0,7.5-3.358,7.5-7.5
   S436.643,364.786,432.5,364.786z'
      />
    </g>
  </svg>
)

export const AbsentIcon = (): ReactElement => (
  <svg viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3.28034 2.21968C2.98745 1.92678 2.51257 1.92677 2.21968 2.21966C1.92678 2.51255 1.92677 2.98743 2.21966 3.28032L8.07441 9.13519C7.44331 9.39951 6.99995 10.023 6.99995 10.75V12H8.49995V10.75L8.50655 10.6927C8.53246 10.5823 8.6316 10.5 8.74995 10.5H9.43923L11.9363 12.9971H3.74904C3.04018 12.9971 2.72696 13.8897 3.28036 14.3327L7.99995 18.1104V19.7546C7.99995 20.9973 9.00731 22.0046 10.2499 22.0046H13.7453C14.988 22.0046 15.9953 20.9973 15.9953 19.7546V18.1103L16.5807 17.6416L20.7194 21.7805C21.0123 22.0734 21.4872 22.0734 21.7801 21.7805C22.073 21.4876 22.073 21.0127 21.7801 20.7198L3.28034 2.21968ZM15.5135 16.5745L14.7765 17.1646C14.5988 17.3069 14.4953 17.5223 14.4953 17.75V19.7546C14.4953 20.1688 14.1595 20.5046 13.7453 20.5046H10.2499C9.83574 20.5046 9.49995 20.1688 9.49995 19.7546V17.75C9.49995 17.5223 9.39645 17.3068 9.21863 17.1645L5.88622 14.4971H13.4362L15.5135 16.5745Z'
      fill='currentColor'
    />
    <path
      d='M11.9999 8.0005C11.656 8.0005 11.3256 7.94263 11.018 7.83609L9.16434 5.98244C9.05781 5.67477 8.99995 5.34439 8.99995 5.0005C8.99995 3.34364 10.3431 2.0005 11.9999 2.0005C13.6568 2.0005 14.9999 3.34364 14.9999 5.0005C14.9999 6.65735 13.6568 8.0005 11.9999 8.0005ZM11.9999 3.5005C11.1715 3.5005 10.4999 4.17207 10.4999 5.0005C10.4999 5.82892 11.1715 6.5005 11.9999 6.5005C12.8284 6.5005 13.4999 5.82892 13.4999 5.0005C13.4999 4.17207 12.8284 3.5005 11.9999 3.5005Z'
      fill='currentColor'
    />
    <path d='M20.2444 12.9971H16.1789L18.9367 15.755L20.7132 14.3326C21.2665 13.8895 20.9532 12.9971 20.2444 12.9971Z' fill='currentColor' />
    <path
      d='M13.6818 10.5L12.1819 9.00004H15.2447C16.1628 9.00004 16.9158 9.70715 16.9889 10.6065L16.9947 10.75V12H15.4947V10.75C15.4947 10.6317 15.4124 10.5325 15.302 10.5066L15.2447 10.5H13.6818Z'
      fill='currentColor'
    />
  </svg>
)
